export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const Announcement = () => import('../../components/Announcement.vue' /* webpackChunkName: "components/announcement" */).then(c => wrapFunctional(c.default || c))
export const AutoFormatTextField = () => import('../../components/AutoFormatTextField.vue' /* webpackChunkName: "components/auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const ChangeLanguageLoading = () => import('../../components/ChangeLanguageLoading.vue' /* webpackChunkName: "components/change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const ContentPageFooter = () => import('../../components/ContentPageFooter.vue' /* webpackChunkName: "components/content-page-footer" */).then(c => wrapFunctional(c.default || c))
export const CopyButton = () => import('../../components/CopyButton.vue' /* webpackChunkName: "components/copy-button" */).then(c => wrapFunctional(c.default || c))
export const CustomQrCode = () => import('../../components/CustomQrCode.vue' /* webpackChunkName: "components/custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const FeaturedGame = () => import('../../components/FeaturedGame.vue' /* webpackChunkName: "components/featured-game" */).then(c => wrapFunctional(c.default || c))
export const FilterForm = () => import('../../components/FilterForm.vue' /* webpackChunkName: "components/filter-form" */).then(c => wrapFunctional(c.default || c))
export const GameCard = () => import('../../components/GameCard.vue' /* webpackChunkName: "components/game-card" */).then(c => wrapFunctional(c.default || c))
export const GameIframeEmbed = () => import('../../components/GameIframeEmbed.vue' /* webpackChunkName: "components/game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const GameLoadFailed = () => import('../../components/GameLoadFailed.vue' /* webpackChunkName: "components/game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const GoBack = () => import('../../components/GoBack.vue' /* webpackChunkName: "components/go-back" */).then(c => wrapFunctional(c.default || c))
export const ImageUpload = () => import('../../components/ImageUpload.vue' /* webpackChunkName: "components/image-upload" */).then(c => wrapFunctional(c.default || c))
export const IsActive = () => import('../../components/IsActive.vue' /* webpackChunkName: "components/is-active" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Maintenance = () => import('../../components/Maintenance.vue' /* webpackChunkName: "components/maintenance" */).then(c => wrapFunctional(c.default || c))
export const Menubar = () => import('../../components/Menubar.vue' /* webpackChunkName: "components/menubar" */).then(c => wrapFunctional(c.default || c))
export const NavContainer = () => import('../../components/NavContainer.vue' /* webpackChunkName: "components/nav-container" */).then(c => wrapFunctional(c.default || c))
export const NuxtImage = () => import('../../components/NuxtImage.vue' /* webpackChunkName: "components/nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumber = () => import('../../components/PhoneNumber.vue' /* webpackChunkName: "components/phone-number" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const QrCode = () => import('../../components/QrCode.vue' /* webpackChunkName: "components/qr-code" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Submenu = () => import('../../components/Submenu.vue' /* webpackChunkName: "components/submenu" */).then(c => wrapFunctional(c.default || c))
export const ThemeMode = () => import('../../components/ThemeMode.vue' /* webpackChunkName: "components/theme-mode" */).then(c => wrapFunctional(c.default || c))
export const DesktopCards = () => import('../../components/Desktop/Cards.vue' /* webpackChunkName: "components/desktop-cards" */).then(c => wrapFunctional(c.default || c))
export const DesktopFooter = () => import('../../components/Desktop/Footer.vue' /* webpackChunkName: "components/desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const DesktopNav = () => import('../../components/Desktop/Nav.vue' /* webpackChunkName: "components/desktop-nav" */).then(c => wrapFunctional(c.default || c))
export const MobileCards = () => import('../../components/Mobile/Cards.vue' /* webpackChunkName: "components/mobile-cards" */).then(c => wrapFunctional(c.default || c))
export const MobileDeposit = () => import('../../components/Mobile/Deposit.vue' /* webpackChunkName: "components/mobile-deposit" */).then(c => wrapFunctional(c.default || c))
export const MobileFooter = () => import('../../components/Mobile/Footer.vue' /* webpackChunkName: "components/mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigationBar = () => import('../../components/Mobile/NavigationBar.vue' /* webpackChunkName: "components/mobile-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const MobileWithdrawal = () => import('../../components/Mobile/Withdrawal.vue' /* webpackChunkName: "components/mobile-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/Skeleton/Loader.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const Theme1FilterForm = () => import('../../components/Theme1/FilterForm.vue' /* webpackChunkName: "components/theme1-filter-form" */).then(c => wrapFunctional(c.default || c))
export const Theme1MainCard = () => import('../../components/Theme1/MainCard.vue' /* webpackChunkName: "components/theme1-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme1ProfileLayout = () => import('../../components/Theme1/ProfileLayout.vue' /* webpackChunkName: "components/theme1-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme1Slider = () => import('../../components/Theme1/Slider.vue' /* webpackChunkName: "components/theme1-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2AgentFloatingIcon = () => import('../../components/Theme2/AgentFloatingIcon.vue' /* webpackChunkName: "components/theme2-agent-floating-icon" */).then(c => wrapFunctional(c.default || c))
export const Theme2Announcement = () => import('../../components/Theme2/Announcement.vue' /* webpackChunkName: "components/theme2-announcement" */).then(c => wrapFunctional(c.default || c))
export const Theme2BetStatus = () => import('../../components/Theme2/BetStatus.vue' /* webpackChunkName: "components/theme2-bet-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2ClaimStatus = () => import('../../components/Theme2/ClaimStatus.vue' /* webpackChunkName: "components/theme2-claim-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2ConfirmDepositDialog = () => import('../../components/Theme2/ConfirmDepositDialog.vue' /* webpackChunkName: "components/theme2-confirm-deposit-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme2DownloadBanner = () => import('../../components/Theme2/DownloadBanner.vue' /* webpackChunkName: "components/theme2-download-banner" */).then(c => wrapFunctional(c.default || c))
export const Theme2FloatingIcon1 = () => import('../../components/Theme2/FloatingIcon1.vue' /* webpackChunkName: "components/theme2-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const Theme2FloatingIcon2 = () => import('../../components/Theme2/FloatingIcon2.vue' /* webpackChunkName: "components/theme2-floating-icon2" */).then(c => wrapFunctional(c.default || c))
export const Theme2HoldBalanceDialog = () => import('../../components/Theme2/HoldBalanceDialog.vue' /* webpackChunkName: "components/theme2-hold-balance-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme2ImageBackground = () => import('../../components/Theme2/ImageBackground.vue' /* webpackChunkName: "components/theme2-image-background" */).then(c => wrapFunctional(c.default || c))
export const Theme2PaymentMethods = () => import('../../components/Theme2/PaymentMethods.vue' /* webpackChunkName: "components/theme2-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const Theme2ProfileLayout = () => import('../../components/Theme2/ProfileLayout.vue' /* webpackChunkName: "components/theme2-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme2TermAndCondition = () => import('../../components/Theme2/TermAndCondition.vue' /* webpackChunkName: "components/theme2-term-and-condition" */).then(c => wrapFunctional(c.default || c))
export const Theme2TransactionStatus = () => import('../../components/Theme2/TransactionStatus.vue' /* webpackChunkName: "components/theme2-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2YoutubeEmbed = () => import('../../components/Theme2/YoutubeEmbed.vue' /* webpackChunkName: "components/theme2-youtube-embed" */).then(c => wrapFunctional(c.default || c))
export const Theme3AgentFoatingIcon = () => import('../../components/Theme3/AgentFoatingIcon.vue' /* webpackChunkName: "components/theme3-agent-foating-icon" */).then(c => wrapFunctional(c.default || c))
export const Theme3Announcement = () => import('../../components/Theme3/Announcement.vue' /* webpackChunkName: "components/theme3-announcement" */).then(c => wrapFunctional(c.default || c))
export const Theme3BetStatus = () => import('../../components/Theme3/BetStatus.vue' /* webpackChunkName: "components/theme3-bet-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3ClaimStatus = () => import('../../components/Theme3/ClaimStatus.vue' /* webpackChunkName: "components/theme3-claim-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3ConfirmDepositDialog = () => import('../../components/Theme3/ConfirmDepositDialog.vue' /* webpackChunkName: "components/theme3-confirm-deposit-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3DownloadBanner = () => import('../../components/Theme3/DownloadBanner.vue' /* webpackChunkName: "components/theme3-download-banner" */).then(c => wrapFunctional(c.default || c))
export const Theme3DraggableMessageDialog = () => import('../../components/Theme3/DraggableMessageDialog.vue' /* webpackChunkName: "components/theme3-draggable-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3FloatingIcon1 = () => import('../../components/Theme3/FloatingIcon1.vue' /* webpackChunkName: "components/theme3-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const Theme3ForceToAddBankAndWallet = () => import('../../components/Theme3/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme3-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme3HoldBalanceDialog = () => import('../../components/Theme3/HoldBalanceDialog.vue' /* webpackChunkName: "components/theme3-hold-balance-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3ImageBackground = () => import('../../components/Theme3/ImageBackground.vue' /* webpackChunkName: "components/theme3-image-background" */).then(c => wrapFunctional(c.default || c))
export const Theme3MainCard = () => import('../../components/Theme3/MainCard.vue' /* webpackChunkName: "components/theme3-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3MenuBar = () => import('../../components/Theme3/MenuBar.vue' /* webpackChunkName: "components/theme3-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3MessageDialog = () => import('../../components/Theme3/MessageDialog.vue' /* webpackChunkName: "components/theme3-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme3PaymentMethods = () => import('../../components/Theme3/PaymentMethods.vue' /* webpackChunkName: "components/theme3-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const Theme3PhoneNumber = () => import('../../components/Theme3/PhoneNumber.vue' /* webpackChunkName: "components/theme3-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Theme3Popup = () => import('../../components/Theme3/Popup.vue' /* webpackChunkName: "components/theme3-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme3ProfileLayout = () => import('../../components/Theme3/ProfileLayout.vue' /* webpackChunkName: "components/theme3-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme3TermAndCondition = () => import('../../components/Theme3/TermAndCondition.vue' /* webpackChunkName: "components/theme3-term-and-condition" */).then(c => wrapFunctional(c.default || c))
export const Theme3TransactionStatus = () => import('../../components/Theme3/TransactionStatus.vue' /* webpackChunkName: "components/theme3-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const Theme3WithdrawalConfirmDeclineDialog = () => import('../../components/Theme3/WithdrawalConfirmDeclineDialog.vue' /* webpackChunkName: "components/theme3-withdrawal-confirm-decline-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme4DatePicker = () => import('../../components/Theme4/DatePicker.vue' /* webpackChunkName: "components/theme4-date-picker" */).then(c => wrapFunctional(c.default || c))
export const Theme4DepositSuccessDialog = () => import('../../components/Theme4/DepositSuccessDialog.vue' /* webpackChunkName: "components/theme4-deposit-success-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme4MainCard = () => import('../../components/Theme4/MainCard.vue' /* webpackChunkName: "components/theme4-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme4MessageDialog = () => import('../../components/Theme4/MessageDialog.vue' /* webpackChunkName: "components/theme4-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme4PhoneNumber = () => import('../../components/Theme4/PhoneNumber.vue' /* webpackChunkName: "components/theme4-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Theme4Popup = () => import('../../components/Theme4/Popup.vue' /* webpackChunkName: "components/theme4-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme4WithdrawalConfirmDeclineDialog = () => import('../../components/Theme4/WithdrawalConfirmDeclineDialog.vue' /* webpackChunkName: "components/theme4-withdrawal-confirm-decline-dialog" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultPromotionPage = () => import('../../components/ThemeDefault/PromotionPage.vue' /* webpackChunkName: "components/theme-default-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const VuetifyPhoneInput = () => import('../../components/Vuetify/PhoneInput.vue' /* webpackChunkName: "components/vuetify-phone-input" */).then(c => wrapFunctional(c.default || c))
export const VuetifyAllCountries = () => import('../../components/Vuetify/all-countries.js' /* webpackChunkName: "components/vuetify-all-countries" */).then(c => wrapFunctional(c.default || c))
export const VuetifyUtils = () => import('../../components/Vuetify/utils.js' /* webpackChunkName: "components/vuetify-utils" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPicesCircle = () => import('../../components/Skeleton/Pices/Circle.vue' /* webpackChunkName: "components/skeleton-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPicesStraight = () => import('../../components/Skeleton/Pices/Straight.vue' /* webpackChunkName: "components/skeleton-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopCards = () => import('../../components/Theme1/Desktop/Cards.vue' /* webpackChunkName: "components/theme1-desktop-cards" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopDepositPage = () => import('../../components/Theme1/Desktop/DepositPage.vue' /* webpackChunkName: "components/theme1-desktop-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopDeposits = () => import('../../components/Theme1/Desktop/Deposits.vue' /* webpackChunkName: "components/theme1-desktop-deposits" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopFavoriteGame = () => import('../../components/Theme1/Desktop/FavoriteGame.vue' /* webpackChunkName: "components/theme1-desktop-favorite-game" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopFooter = () => import('../../components/Theme1/Desktop/Footer.vue' /* webpackChunkName: "components/theme1-desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopMenuBar = () => import('../../components/Theme1/Desktop/MenuBar.vue' /* webpackChunkName: "components/theme1-desktop-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNav = () => import('../../components/Theme1/Desktop/Nav.vue' /* webpackChunkName: "components/theme1-desktop-nav" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavBar = () => import('../../components/Theme1/Desktop/NavBar.vue' /* webpackChunkName: "components/theme1-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopPromotion = () => import('../../components/Theme1/Desktop/Promotion.vue' /* webpackChunkName: "components/theme1-desktop-promotion" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopPromotionDetail = () => import('../../components/Theme1/Desktop/PromotionDetail.vue' /* webpackChunkName: "components/theme1-desktop-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopRegisterForm = () => import('../../components/Theme1/Desktop/RegisterForm.vue' /* webpackChunkName: "components/theme1-desktop-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopSubMenu = () => import('../../components/Theme1/Desktop/SubMenu.vue' /* webpackChunkName: "components/theme1-desktop-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopWithdrawals = () => import('../../components/Theme1/Desktop/Withdrawals.vue' /* webpackChunkName: "components/theme1-desktop-withdrawals" */).then(c => wrapFunctional(c.default || c))
export const Theme1MobileNavBar = () => import('../../components/Theme1/Mobile/NavBar.vue' /* webpackChunkName: "components/theme1-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesDepositApply = () => import('../../components/Theme1/Pages/DepositApply.vue' /* webpackChunkName: "components/theme1-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesMyBonus = () => import('../../components/Theme1/Pages/MyBonus.vue' /* webpackChunkName: "components/theme1-pages-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesPlayerProfile = () => import('../../components/Theme1/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme1-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesWithdrawalApply = () => import('../../components/Theme1/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme1-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme2ArticleContentComponent = () => import('../../components/Theme2/Article/ContentComponent.vue' /* webpackChunkName: "components/theme2-article-content-component" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsClaimBonusButton = () => import('../../components/Theme2/Components/ClaimBonusButton.vue' /* webpackChunkName: "components/theme2-components-claim-bonus-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsExchangeRate = () => import('../../components/Theme2/Components/ExchangeRate.vue' /* webpackChunkName: "components/theme2-components-exchange-rate" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopCashier = () => import('../../components/Theme2/Desktop/Cashier.vue' /* webpackChunkName: "components/theme2-desktop-cashier" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopDepositStep = () => import('../../components/Theme2/Desktop/DepositStep.vue' /* webpackChunkName: "components/theme2-desktop-deposit-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent1 = () => import('../../components/Theme2/Desktop/FeaturedContent1.vue' /* webpackChunkName: "components/theme2-desktop-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent2 = () => import('../../components/Theme2/Desktop/FeaturedContent2.vue' /* webpackChunkName: "components/theme2-desktop-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent3 = () => import('../../components/Theme2/Desktop/FeaturedContent3.vue' /* webpackChunkName: "components/theme2-desktop-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFooter = () => import('../../components/Theme2/Desktop/Footer.vue' /* webpackChunkName: "components/theme2-desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopForceToAddBankAndWallet = () => import('../../components/Theme2/Desktop/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme2-desktop-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopHoldBalance = () => import('../../components/Theme2/Desktop/HoldBalance.vue' /* webpackChunkName: "components/theme2-desktop-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopHomeSlider = () => import('../../components/Theme2/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme2-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopInterRegisterForm = () => import('../../components/Theme2/Desktop/InterRegisterForm.vue' /* webpackChunkName: "components/theme2-desktop-inter-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopJackpotContent = () => import('../../components/Theme2/Desktop/JackpotContent.vue' /* webpackChunkName: "components/theme2-desktop-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLoginForm = () => import('../../components/Theme2/Desktop/LoginForm.vue' /* webpackChunkName: "components/theme2-desktop-login-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLotteryWinnerCount = () => import('../../components/Theme2/Desktop/LotteryWinnerCount.vue' /* webpackChunkName: "components/theme2-desktop-lottery-winner-count" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLotteryWinners = () => import('../../components/Theme2/Desktop/LotteryWinners.vue' /* webpackChunkName: "components/theme2-desktop-lottery-winners" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopMainCard = () => import('../../components/Theme2/Desktop/MainCard.vue' /* webpackChunkName: "components/theme2-desktop-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopMenuBar = () => import('../../components/Theme2/Desktop/MenuBar.vue' /* webpackChunkName: "components/theme2-desktop-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavBar = () => import('../../components/Theme2/Desktop/NavBar.vue' /* webpackChunkName: "components/theme2-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfileMainSection = () => import('../../components/Theme2/Desktop/ProfileMainSection.vue' /* webpackChunkName: "components/theme2-desktop-profile-main-section" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPromotionCard = () => import('../../components/Theme2/Desktop/PromotionCard.vue' /* webpackChunkName: "components/theme2-desktop-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPromotionContent = () => import('../../components/Theme2/Desktop/PromotionContent.vue' /* webpackChunkName: "components/theme2-desktop-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopRecentWinners = () => import('../../components/Theme2/Desktop/RecentWinners.vue' /* webpackChunkName: "components/theme2-desktop-recent-winners" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopRegisterForm = () => import('../../components/Theme2/Desktop/RegisterForm.vue' /* webpackChunkName: "components/theme2-desktop-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopSubMenu = () => import('../../components/Theme2/Desktop/SubMenu.vue' /* webpackChunkName: "components/theme2-desktop-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopVideoContent1And2 = () => import('../../components/Theme2/Desktop/VideoContent1And2.vue' /* webpackChunkName: "components/theme2-desktop-video-content1-and2" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopWithdrawStep = () => import('../../components/Theme2/Desktop/WithdrawStep.vue' /* webpackChunkName: "components/theme2-desktop-withdraw-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileAddBank = () => import('../../components/Theme2/Mobile/AddBank.vue' /* webpackChunkName: "components/theme2-mobile-add-bank" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositStep = () => import('../../components/Theme2/Mobile/DepositStep.vue' /* webpackChunkName: "components/theme2-mobile-deposit-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent1 = () => import('../../components/Theme2/Mobile/FeaturedContent1.vue' /* webpackChunkName: "components/theme2-mobile-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent2 = () => import('../../components/Theme2/Mobile/FeaturedContent2.vue' /* webpackChunkName: "components/theme2-mobile-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent3 = () => import('../../components/Theme2/Mobile/FeaturedContent3.vue' /* webpackChunkName: "components/theme2-mobile-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFooterComponent = () => import('../../components/Theme2/Mobile/FooterComponent.vue' /* webpackChunkName: "components/theme2-mobile-footer-component" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileForceToAddBankAndWallet = () => import('../../components/Theme2/Mobile/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme2-mobile-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileGameHistory = () => import('../../components/Theme2/Mobile/GameHistory.vue' /* webpackChunkName: "components/theme2-mobile-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileHoldBalance = () => import('../../components/Theme2/Mobile/HoldBalance.vue' /* webpackChunkName: "components/theme2-mobile-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileHomeSlider = () => import('../../components/Theme2/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme2-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileInterRegisterForm = () => import('../../components/Theme2/Mobile/InterRegisterForm.vue' /* webpackChunkName: "components/theme2-mobile-inter-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileJackpotContent = () => import('../../components/Theme2/Mobile/JackpotContent.vue' /* webpackChunkName: "components/theme2-mobile-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileLoginForm = () => import('../../components/Theme2/Mobile/LoginForm.vue' /* webpackChunkName: "components/theme2-mobile-login-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileOnly = () => import('../../components/Theme2/Mobile/MobileOnly.vue' /* webpackChunkName: "components/theme2-mobile-only" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileMyHistory = () => import('../../components/Theme2/Mobile/MyHistory.vue' /* webpackChunkName: "components/theme2-mobile-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileNavBar = () => import('../../components/Theme2/Mobile/NavBar.vue' /* webpackChunkName: "components/theme2-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePlayerProfile = () => import('../../components/Theme2/Mobile/PlayerProfile.vue' /* webpackChunkName: "components/theme2-mobile-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePromotionContent = () => import('../../components/Theme2/Mobile/PromotionContent.vue' /* webpackChunkName: "components/theme2-mobile-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileReferFriend = () => import('../../components/Theme2/Mobile/ReferFriend.vue' /* webpackChunkName: "components/theme2-mobile-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileRegisterForm = () => import('../../components/Theme2/Mobile/RegisterForm.vue' /* webpackChunkName: "components/theme2-mobile-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileTitleAlert = () => import('../../components/Theme2/Mobile/TitleAlert.vue' /* webpackChunkName: "components/theme2-mobile-title-alert" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileVideoContent1And2 = () => import('../../components/Theme2/Mobile/VideoContent1And2.vue' /* webpackChunkName: "components/theme2-mobile-video-content1-and2" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawStep = () => import('../../components/Theme2/Mobile/WithdrawStep.vue' /* webpackChunkName: "components/theme2-mobile-withdraw-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2PagesDepositApply = () => import('../../components/Theme2/Pages/DepositApply.vue' /* webpackChunkName: "components/theme2-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme2PagesHoldBalance = () => import('../../components/Theme2/Pages/HoldBalance.vue' /* webpackChunkName: "components/theme2-pages-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme2PagesMyBonus = () => import('../../components/Theme2/Pages/MyBonus.vue' /* webpackChunkName: "components/theme2-pages-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme2PagesPlayerProfile = () => import('../../components/Theme2/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme2-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme2PagesWithdrawalApply = () => import('../../components/Theme2/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme2-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsActiveBonus = () => import('../../components/Theme3/Components/ActiveBonus.vue' /* webpackChunkName: "components/theme3-components-active-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsActiveBonusLoading = () => import('../../components/Theme3/Components/ActiveBonusLoading.vue' /* webpackChunkName: "components/theme3-components-active-bonus-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimBonusButton = () => import('../../components/Theme3/Components/ClaimBonusButton.vue' /* webpackChunkName: "components/theme3-components-claim-bonus-button" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimHistory = () => import('../../components/Theme3/Components/ClaimHistory.vue' /* webpackChunkName: "components/theme3-components-claim-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimPromoCode = () => import('../../components/Theme3/Components/ClaimPromoCode.vue' /* webpackChunkName: "components/theme3-components-claim-promo-code" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsClaimableSwiperLoading = () => import('../../components/Theme3/Components/ClaimableSwiperLoading.vue' /* webpackChunkName: "components/theme3-components-claimable-swiper-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsDepositIsInProgress = () => import('../../components/Theme3/Components/DepositIsInProgress.vue' /* webpackChunkName: "components/theme3-components-deposit-is-in-progress" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsEndBonusLoading = () => import('../../components/Theme3/Components/EndBonusLoading.vue' /* webpackChunkName: "components/theme3-components-end-bonus-loading" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsExchangeRate = () => import('../../components/Theme3/Components/ExchangeRate.vue' /* webpackChunkName: "components/theme3-components-exchange-rate" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsFriendList = () => import('../../components/Theme3/Components/FriendList.vue' /* webpackChunkName: "components/theme3-components-friend-list" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsNoActiveBonus = () => import('../../components/Theme3/Components/NoActiveBonus.vue' /* webpackChunkName: "components/theme3-components-no-active-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsOnholdErrorPopup = () => import('../../components/Theme3/Components/OnholdErrorPopup.vue' /* webpackChunkName: "components/theme3-components-onhold-error-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsSideMenu = () => import('../../components/Theme3/Components/SideMenu.vue' /* webpackChunkName: "components/theme3-components-side-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme3ComponentsWithdrawalErrorPopup = () => import('../../components/Theme3/Components/WithdrawalErrorPopup.vue' /* webpackChunkName: "components/theme3-components-withdrawal-error-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme3ArticleContentComponent = () => import('../../components/Theme3/Article/ContentComponent.vue' /* webpackChunkName: "components/theme3-article-content-component" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopBigPromotionCard = () => import('../../components/Theme3/Desktop/BigPromotionCard.vue' /* webpackChunkName: "components/theme3-desktop-big-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopCashier = () => import('../../components/Theme3/Desktop/Cashier.vue' /* webpackChunkName: "components/theme3-desktop-cashier" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopCustomFooter = () => import('../../components/Theme3/Desktop/CustomFooter.vue' /* webpackChunkName: "components/theme3-desktop-custom-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopFeaturedContent1 = () => import('../../components/Theme3/Desktop/FeaturedContent1.vue' /* webpackChunkName: "components/theme3-desktop-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopFeaturedContent2 = () => import('../../components/Theme3/Desktop/FeaturedContent2.vue' /* webpackChunkName: "components/theme3-desktop-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopHoldBalance = () => import('../../components/Theme3/Desktop/HoldBalance.vue' /* webpackChunkName: "components/theme3-desktop-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopHomeSlider = () => import('../../components/Theme3/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme3-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopInterRegisterModal = () => import('../../components/Theme3/Desktop/InterRegisterModal.vue' /* webpackChunkName: "components/theme3-desktop-inter-register-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopLanguageSwitcher = () => import('../../components/Theme3/Desktop/LanguageSwitcher.vue' /* webpackChunkName: "components/theme3-desktop-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopLoginModal = () => import('../../components/Theme3/Desktop/LoginModal.vue' /* webpackChunkName: "components/theme3-desktop-login-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopNavBar = () => import('../../components/Theme3/Desktop/NavBar.vue' /* webpackChunkName: "components/theme3-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPromotionCard = () => import('../../components/Theme3/Desktop/PromotionCard.vue' /* webpackChunkName: "components/theme3-desktop-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPromotionContent = () => import('../../components/Theme3/Desktop/PromotionContent.vue' /* webpackChunkName: "components/theme3-desktop-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopRegisterModal = () => import('../../components/Theme3/Desktop/RegisterModal.vue' /* webpackChunkName: "components/theme3-desktop-register-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileAddBank = () => import('../../components/Theme3/Mobile/AddBank.vue' /* webpackChunkName: "components/theme3-mobile-add-bank" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileCustomFooter = () => import('../../components/Theme3/Mobile/CustomFooter.vue' /* webpackChunkName: "components/theme3-mobile-custom-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileCustomFooterTest = () => import('../../components/Theme3/Mobile/CustomFooterTest.vue' /* webpackChunkName: "components/theme3-mobile-custom-footer-test" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileExchangeRateModal = () => import('../../components/Theme3/Mobile/ExchangeRateModal.vue' /* webpackChunkName: "components/theme3-mobile-exchange-rate-modal" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileGameHistory = () => import('../../components/Theme3/Mobile/GameHistory.vue' /* webpackChunkName: "components/theme3-mobile-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileGameMenu = () => import('../../components/Theme3/Mobile/GameMenu.vue' /* webpackChunkName: "components/theme3-mobile-game-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileHoldBalance = () => import('../../components/Theme3/Mobile/HoldBalance.vue' /* webpackChunkName: "components/theme3-mobile-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileHomeSlider = () => import('../../components/Theme3/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme3-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileMenuDrawer = () => import('../../components/Theme3/Mobile/MenuDrawer.vue' /* webpackChunkName: "components/theme3-mobile-menu-drawer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileMyHistory = () => import('../../components/Theme3/Mobile/MyHistory.vue' /* webpackChunkName: "components/theme3-mobile-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileNavBar = () => import('../../components/Theme3/Mobile/NavBar.vue' /* webpackChunkName: "components/theme3-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileReferFriend = () => import('../../components/Theme3/Mobile/ReferFriend.vue' /* webpackChunkName: "components/theme3-mobile-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileTitleAlert = () => import('../../components/Theme3/Mobile/TitleAlert.vue' /* webpackChunkName: "components/theme3-mobile-title-alert" */).then(c => wrapFunctional(c.default || c))
export const Theme3PagesDepositApply = () => import('../../components/Theme3/Pages/DepositApply.vue' /* webpackChunkName: "components/theme3-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme3PagesHoldBalance = () => import('../../components/Theme3/Pages/HoldBalance.vue' /* webpackChunkName: "components/theme3-pages-hold-balance" */).then(c => wrapFunctional(c.default || c))
export const Theme3PagesMyBonus = () => import('../../components/Theme3/Pages/MyBonus.vue' /* webpackChunkName: "components/theme3-pages-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3PagesPlayerProfile = () => import('../../components/Theme3/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme3-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme3PagesWithdrawalApply = () => import('../../components/Theme3/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme3-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopBonusGame = () => import('../../components/Theme4/Desktop/BonusGame.vue' /* webpackChunkName: "components/theme4-desktop-bonus-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopGameAndSport = () => import('../../components/Theme4/Desktop/GameAndSport.vue' /* webpackChunkName: "components/theme4-desktop-game-and-sport" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHomeFooter = () => import('../../components/Theme4/Desktop/HomeFooter.vue' /* webpackChunkName: "components/theme4-desktop-home-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHomeSlider = () => import('../../components/Theme4/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme4-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHotGame = () => import('../../components/Theme4/Desktop/HotGame.vue' /* webpackChunkName: "components/theme4-desktop-hot-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopLeaderBoard = () => import('../../components/Theme4/Desktop/LeaderBoard.vue' /* webpackChunkName: "components/theme4-desktop-leader-board" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopLiveCasino = () => import('../../components/Theme4/Desktop/LiveCasino.vue' /* webpackChunkName: "components/theme4-desktop-live-casino" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopNavBar = () => import('../../components/Theme4/Desktop/NavBar.vue' /* webpackChunkName: "components/theme4-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopProfileDropdown = () => import('../../components/Theme4/Desktop/ProfileDropdown.vue' /* webpackChunkName: "components/theme4-desktop-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPromotionComponent = () => import('../../components/Theme4/Desktop/PromotionComponent.vue' /* webpackChunkName: "components/theme4-desktop-promotion-component" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopSportGame = () => import('../../components/Theme4/Desktop/SportGame.vue' /* webpackChunkName: "components/theme4-desktop-sport-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopSwitchLanguage = () => import('../../components/Theme4/Desktop/SwitchLanguage.vue' /* webpackChunkName: "components/theme4-desktop-switch-language" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileBonusGame = () => import('../../components/Theme4/Mobile/BonusGame.vue' /* webpackChunkName: "components/theme4-mobile-bonus-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileHomeSlider = () => import('../../components/Theme4/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme4-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileLeaderBoard = () => import('../../components/Theme4/Mobile/LeaderBoard.vue' /* webpackChunkName: "components/theme4-mobile-leader-board" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileMobHeader = () => import('../../components/Theme4/Mobile/MobHeader.vue' /* webpackChunkName: "components/theme4-mobile-mob-header" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileNavBar = () => import('../../components/Theme4/Mobile/NavBar.vue' /* webpackChunkName: "components/theme4-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePromotionComponent = () => import('../../components/Theme4/Mobile/PromotionComponent.vue' /* webpackChunkName: "components/theme4-mobile-promotion-component" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesBonusPage = () => import('../../components/Theme4/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesDepositApply = () => import('../../components/Theme4/Pages/DepositApply.vue' /* webpackChunkName: "components/theme4-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesPromotionPage = () => import('../../components/Theme4/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesRecentlyPage = () => import('../../components/Theme4/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesWithdrawalApply = () => import('../../components/Theme4/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme4-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultMobileNavBar = () => import('../../components/ThemeDefault/Mobile/NavBar.vue' /* webpackChunkName: "components/theme-default-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultPagesPlayerProfile = () => import('../../components/ThemeDefault/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme-default-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultPagesWithdrawalApply = () => import('../../components/ThemeDefault/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme-default-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavLoginPopup = () => import('../../components/Theme1/Desktop/Nav/LoginPopup.vue' /* webpackChunkName: "components/theme1-desktop-nav-login-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavMessageDropdown = () => import('../../components/Theme1/Desktop/Nav/MessageDropdown.vue' /* webpackChunkName: "components/theme1-desktop-nav-message-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavProfileDropdown = () => import('../../components/Theme1/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme1-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesBankDetail = () => import('../../components/Theme1/Desktop/Profiles/BankDetail.vue' /* webpackChunkName: "components/theme1-desktop-profiles-bank-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositPage = () => import('../../components/Theme1/Desktop/Profiles/DepositPage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyBonus = () => import('../../components/Theme1/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyHistory = () => import('../../components/Theme1/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyMessage = () => import('../../components/Theme1/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyProfile = () => import('../../components/Theme1/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesReferFriend = () => import('../../components/Theme1/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme1-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesResetPassword = () => import('../../components/Theme1/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme1-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesWithdrawalPage = () => import('../../components/Theme1/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFloatingIconsAgentSupportButton = () => import('../../components/Theme2/Desktop/FloatingIcons/AgentSupportButton.vue' /* webpackChunkName: "components/theme2-desktop-floating-icons-agent-support-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFloatingIconsMessageButton = () => import('../../components/Theme2/Desktop/FloatingIcons/MessageButton.vue' /* webpackChunkName: "components/theme2-desktop-floating-icons-message-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFloatingIconsRankingButton = () => import('../../components/Theme2/Desktop/FloatingIcons/RankingButton.vue' /* webpackChunkName: "components/theme2-desktop-floating-icons-ranking-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFloatingIconsSupportButton = () => import('../../components/Theme2/Desktop/FloatingIcons/SupportButton.vue' /* webpackChunkName: "components/theme2-desktop-floating-icons-support-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavMessageDropdown = () => import('../../components/Theme2/Desktop/Nav/MessageDropdown.vue' /* webpackChunkName: "components/theme2-desktop-nav-message-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavProfileDropdown = () => import('../../components/Theme2/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme2-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPagesProfilePage = () => import('../../components/Theme2/Desktop/Pages/ProfilePage.vue' /* webpackChunkName: "components/theme2-desktop-pages-profile-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPagesPromotionDetail = () => import('../../components/Theme2/Desktop/Pages/PromotionDetail.vue' /* webpackChunkName: "components/theme2-desktop-pages-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPagesPromotionPage = () => import('../../components/Theme2/Desktop/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme2-desktop-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankAccountCard = () => import('../../components/Theme2/Desktop/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankAddEmptyCard = () => import('../../components/Theme2/Desktop/Profiles/BankAddEmptyCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-add-empty-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankDetail = () => import('../../components/Theme2/Desktop/Profiles/BankDetail.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesCryptoEmptyCard = () => import('../../components/Theme2/Desktop/Profiles/CryptoEmptyCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-crypto-empty-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesCryptoWalletCard = () => import('../../components/Theme2/Desktop/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositPage = () => import('../../components/Theme2/Desktop/Profiles/DepositPage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesGameHistory = () => import('../../components/Theme2/Desktop/Profiles/GameHistory.vue' /* webpackChunkName: "components/theme2-desktop-profiles-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyBonus = () => import('../../components/Theme2/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyHistory = () => import('../../components/Theme2/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyMessage = () => import('../../components/Theme2/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyProfile = () => import('../../components/Theme2/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesNoData = () => import('../../components/Theme2/Desktop/Profiles/NoData.vue' /* webpackChunkName: "components/theme2-desktop-profiles-no-data" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesReferFriend = () => import('../../components/Theme2/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme2-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesResetPassword = () => import('../../components/Theme2/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme2-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesWithdrawalPage = () => import('../../components/Theme2/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsBankTransfer = () => import('../../components/Theme2/Mobile/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme2-mobile-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsCryptoPayment = () => import('../../components/Theme2/Mobile/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme2-mobile-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsQRTransfer = () => import('../../components/Theme2/Mobile/Deposits/QRTransfer.vue' /* webpackChunkName: "components/theme2-mobile-deposits-q-r-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsThirdPartyPayment = () => import('../../components/Theme2/Mobile/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme2-mobile-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDisplaysGameBlock = () => import('../../components/Theme2/Mobile/Displays/GameBlock.vue' /* webpackChunkName: "components/theme2-mobile-displays-game-block" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesDepositPage = () => import('../../components/Theme2/Mobile/Pages/DepositPage.vue' /* webpackChunkName: "components/theme2-mobile-pages-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesGamePage = () => import('../../components/Theme2/Mobile/Pages/GamePage.vue' /* webpackChunkName: "components/theme2-mobile-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesWithdrawalPage = () => import('../../components/Theme2/Mobile/Pages/WithdrawalPage.vue' /* webpackChunkName: "components/theme2-mobile-pages-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesBankAccountCard = () => import('../../components/Theme2/Mobile/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme2-mobile-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesCryptoWalletCard = () => import('../../components/Theme2/Mobile/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme2-mobile-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesMyBank = () => import('../../components/Theme2/Mobile/Profiles/MyBank.vue' /* webpackChunkName: "components/theme2-mobile-profiles-my-bank" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesMyCrypto = () => import('../../components/Theme2/Mobile/Profiles/MyCrypto.vue' /* webpackChunkName: "components/theme2-mobile-profiles-my-crypto" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawalsBankTransfer = () => import('../../components/Theme2/Mobile/Withdrawals/BankTransfer.vue' /* webpackChunkName: "components/theme2-mobile-withdrawals-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawalsCryptoPayment = () => import('../../components/Theme2/Mobile/Withdrawals/CryptoPayment.vue' /* webpackChunkName: "components/theme2-mobile-withdrawals-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopNavProfileDropdown = () => import('../../components/Theme3/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme3-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesInterRegisterPage = () => import('../../components/Theme3/Desktop/Pages/InterRegisterPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-inter-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesLoginPage = () => import('../../components/Theme3/Desktop/Pages/LoginPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-login-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesPromotionDetail = () => import('../../components/Theme3/Desktop/Pages/PromotionDetail.vue' /* webpackChunkName: "components/theme3-desktop-pages-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesPromotionPage = () => import('../../components/Theme3/Desktop/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPagesRegisterPage = () => import('../../components/Theme3/Desktop/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme3-desktop-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodBankTransfer = () => import('../../components/Theme3/Desktop/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodCryptoCurrency = () => import('../../components/Theme3/Desktop/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopPaymentMethodThirdPartyPayment = () => import('../../components/Theme3/Desktop/PaymentMethod/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme3-desktop-payment-method-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesBankAccountCard = () => import('../../components/Theme3/Desktop/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme3-desktop-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesCryptoWalletCard = () => import('../../components/Theme3/Desktop/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme3-desktop-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesGameHistory = () => import('../../components/Theme3/Desktop/Profiles/GameHistory.vue' /* webpackChunkName: "components/theme3-desktop-profiles-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyBonus = () => import('../../components/Theme3/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyDeposit = () => import('../../components/Theme3/Desktop/Profiles/MyDeposit.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyHistory = () => import('../../components/Theme3/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyMessage = () => import('../../components/Theme3/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesMyProfile = () => import('../../components/Theme3/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme3-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesReferFriend = () => import('../../components/Theme3/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme3-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesResetPassword = () => import('../../components/Theme3/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme3-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme3DesktopProfilesWithdrawalPage = () => import('../../components/Theme3/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme3-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsBankTransfer = () => import('../../components/Theme3/Mobile/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme3-mobile-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsCryptoPayment = () => import('../../components/Theme3/Mobile/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme3-mobile-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileDepositsThirdPartyPayment = () => import('../../components/Theme3/Mobile/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme3-mobile-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesInterRegisterPage = () => import('../../components/Theme3/Mobile/Pages/InterRegisterPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-inter-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesRegisterPage = () => import('../../components/Theme3/Mobile/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobilePagesWithdrawalPage = () => import('../../components/Theme3/Mobile/Pages/WithdrawalPage.vue' /* webpackChunkName: "components/theme3-mobile-pages-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesBankAccountCard = () => import('../../components/Theme3/Mobile/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme3-mobile-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyBonus = () => import('../../components/Theme3/Mobile/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyDeposit = () => import('../../components/Theme3/Mobile/Profiles/MyDeposit.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileProfilesMyProfile = () => import('../../components/Theme3/Mobile/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme3-mobile-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileWithdrawalsBankTransfer = () => import('../../components/Theme3/Mobile/Withdrawals/BankTransfer.vue' /* webpackChunkName: "components/theme3-mobile-withdrawals-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme3MobileWithdrawalsCryptoTransfer = () => import('../../components/Theme3/Mobile/Withdrawals/CryptoTransfer.vue' /* webpackChunkName: "components/theme3-mobile-withdrawals-crypto-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesBonusPage = () => import('../../components/Theme4/Desktop/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesGamePage = () => import('../../components/Theme4/Desktop/Pages/GamePage.vue' /* webpackChunkName: "components/theme4-desktop-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesLoginPage = () => import('../../components/Theme4/Desktop/Pages/LoginPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-login-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesPromotionPage = () => import('../../components/Theme4/Desktop/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesRecentlyPage = () => import('../../components/Theme4/Desktop/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesReferFriend = () => import('../../components/Theme4/Desktop/Pages/ReferFriend.vue' /* webpackChunkName: "components/theme4-desktop-pages-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesRegisterPage = () => import('../../components/Theme4/Desktop/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodBankTransfer = () => import('../../components/Theme4/Desktop/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodBankWithdrawal = () => import('../../components/Theme4/Desktop/PaymentMethod/BankWithdrawal.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-bank-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodCryptoCurrency = () => import('../../components/Theme4/Desktop/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodMyDeposit = () => import('../../components/Theme4/Desktop/PaymentMethod/MyDeposit.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodMyWithdrawal = () => import('../../components/Theme4/Desktop/PaymentMethod/MyWithdrawal.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-my-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodOnlinePayment = () => import('../../components/Theme4/Desktop/PaymentMethod/OnlinePayment.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-online-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodQuickPayment = () => import('../../components/Theme4/Desktop/PaymentMethod/QuickPayment.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-quick-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodWithdrawalCrypto = () => import('../../components/Theme4/Desktop/PaymentMethod/WithdrawalCrypto.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-withdrawal-crypto" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesBonusPage = () => import('../../components/Theme4/Mobile/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesGamePage = () => import('../../components/Theme4/Mobile/Pages/GamePage.vue' /* webpackChunkName: "components/theme4-mobile-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesLoginPage = () => import('../../components/Theme4/Mobile/Pages/LoginPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-login-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesPromotionPage = () => import('../../components/Theme4/Mobile/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesRecentlyPage = () => import('../../components/Theme4/Mobile/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesReferFriend = () => import('../../components/Theme4/Mobile/Pages/ReferFriend.vue' /* webpackChunkName: "components/theme4-mobile-pages-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesRegisterPage = () => import('../../components/Theme4/Mobile/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodBankTransfer = () => import('../../components/Theme4/Mobile/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodBankWithdrawal = () => import('../../components/Theme4/Mobile/PaymentMethod/BankWithdrawal.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-bank-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodCryptoCurrency = () => import('../../components/Theme4/Mobile/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodMyDeposit = () => import('../../components/Theme4/Mobile/PaymentMethod/MyDeposit.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodMyWithdrawal = () => import('../../components/Theme4/Mobile/PaymentMethod/MyWithdrawal.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-my-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodOnlinePayment = () => import('../../components/Theme4/Mobile/PaymentMethod/OnlinePayment.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-online-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodQuickPayment = () => import('../../components/Theme4/Mobile/PaymentMethod/QuickPayment.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-quick-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodWithdrawalCrypto = () => import('../../components/Theme4/Mobile/PaymentMethod/WithdrawalCrypto.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-withdrawal-crypto" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultDesktopPagesDepositPage = () => import('../../components/ThemeDefault/Desktop/Pages/DepositPage.vue' /* webpackChunkName: "components/theme-default-desktop-pages-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultDesktopPagesGamePage = () => import('../../components/ThemeDefault/Desktop/Pages/GamePage.vue' /* webpackChunkName: "components/theme-default-desktop-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultDesktopPagesMessage = () => import('../../components/ThemeDefault/Desktop/Pages/Message.vue' /* webpackChunkName: "components/theme-default-desktop-pages-message" */).then(c => wrapFunctional(c.default || c))
export const ThemeDefaultDesktopPagesProfilePage = () => import('../../components/ThemeDefault/Desktop/Pages/ProfilePage.vue' /* webpackChunkName: "components/theme-default-desktop-pages-profile-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsBankTransfer = () => import('../../components/Theme1/Desktop/Profiles/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsCryptoPayment = () => import('../../components/Theme1/Desktop/Profiles/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsOnlineBank = () => import('../../components/Theme1/Desktop/Profiles/Deposits/OnlineBank.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-online-bank" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsBankTransfer = () => import('../../components/Theme2/Desktop/Profiles/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsCryptoPayment = () => import('../../components/Theme2/Desktop/Profiles/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsQRTransfer = () => import('../../components/Theme2/Desktop/Profiles/Deposits/QRTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-q-r-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsThirdPartyPayment = () => import('../../components/Theme2/Desktop/Profiles/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
